import {Group} from "@mantine/core";
import React from "react";

// TODO: Add link to the article
export const BLOG_ARTICLE_LINK = "https://fingerprint.com/blog/vpn-detection-how-it-works/"
export const BLOG_LINK = "https://fingerprint.com/blog/"

export function Header() {
    return (
        <Group
            pos="absolute"
            top={0}
            w="100%"
            position="right"
            spacing="50px"
            p="30px 50px"
        >
            <a
                href={BLOG_ARTICLE_LINK}
                style={{color: "#228be6"}}
                target="_blank"
                rel="noreferrer"
            >
                Article
            </a>
        </Group>
    );
}
