import FingerprintJS, {defaultScriptUrlPattern} from '@fingerprintjs/fingerprintjs-pro'

export const OS_NAMES = {
    Windows: "Windows",
    Linux: "Linux",
    MacOS: "Mac OS X",
    Android: "Android",
    IOS: "iOS",
    Other: "Other",
    Unknown: 'Unknown'
}
const OS_NAMES_TYPES = Object.values(OS_NAMES)
export type OsNames = typeof OS_NAMES_TYPES[number]

interface Credentials {
    apiHost: string
    publicKey: string
    fpHost: string
}

export interface Result {
    name: string
    desc: string
    result: string
    details: string
}

export async function getCredentials(): Promise<Credentials | undefined> {
    const getCredentialsURL = () => {
        const url = new URL(window.location.origin)
        url.pathname += `api/config`
        return url.href
    }

    const resp = await fetch(getCredentialsURL(), {method: 'GET'})
    const respJson = await resp.json()
    if (respJson.error !== undefined) {
        console.error(respJson.error)
        return undefined
    }
    return respJson as Credentials
}

export async function getVpnDetection(requestId: string, data: any, os: string): Promise<any> {
    const getVpnURL = () => {
        const url = new URL(window.location.origin)
        url.pathname += `api/vpn`
        return url.href
    }

    const bodyJson = {ri: requestId, r: data, o: os}
    const resp = await fetch(getVpnURL(), {method: 'POST', body: JSON.stringify(bodyJson)})
    const respJson = await resp.json()
    if (respJson.error !== undefined) {
        console.error(respJson.error)
        return undefined
    }
    return respJson as Result[]
}

export async function invokeFp(cred: Credentials) {
    const fp = await FingerprintJS.load({apiKey: cred.publicKey, endpoint: cred.fpHost,
        scriptUrlPattern: [
            `https://metrics.botd.xyz/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
            defaultScriptUrlPattern,
        ],})
    return await fp.get()
}

export async function invokeTcpIpFp(cred: Credentials) {
    const url = cred.apiHost
    const response = await fetch(url)
    return await response.json()
}

export function getClientOsByUa(): OsNames {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1)
        return OS_NAMES.MacOS
    else if (iosPlatforms.indexOf(platform) !== -1)
        return OS_NAMES.IOS
    else if (windowsPlatforms.indexOf(platform) !== -1)
        return OS_NAMES.Windows
    else if (/Android/.test(userAgent))
        return OS_NAMES.Android
    else if (/Linux/.test(platform))
        return OS_NAMES.Linux
    return OS_NAMES.Unknown
}
