import {
    Container,
    Text,
    Title,
    createStyles,
    rem, Table, Loader, Center, Alert,
} from "@mantine/core"
import {Wrapper} from "./Wrapper/Wrapper"
import {BLOG_ARTICLE_LINK, BLOG_LINK, Header} from "./Header/Header"
import {Fragment, useEffect, useState} from "react";
import {IconAlertCircle} from '@tabler/icons-react';
import {getClientOsByUa, getCredentials, getVpnDetection, invokeFp, invokeTcpIpFp, Result} from "../detection";

const useStyles = createStyles((theme) => ({
    title: {
        textAlign: "center",
        fontWeight: 800,
        fontSize: rem(40),
        letterSpacing: -1,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        marginBottom: theme.spacing.xs,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    description: {
        textAlign: "center",
    },
}));
const os = getClientOsByUa()

export default function App() {
    const {classes} = useStyles();
    const [result, setResult] = useState<Result[]>();
    const [error, setError] = useState<undefined | string>();

    useEffect(() => {
        getCredentials()
            .then((cred) => {
                if (cred) {
                    Promise.all([invokeFp(cred), invokeTcpIpFp(cred)]).then((values) => {
                        const [fpResult, tcpIpResult] = values
                        const requestId = fpResult['requestId']
                        getVpnDetection(requestId, tcpIpResult, os).then((res) => {
                            if (res) setResult(res)
                        })
                    })
                } else
                    setError("API error")
            }).catch((err) => {
            setError(err.toString())
        })
    }, [])

    const getPage = (content: JSX.Element) => {
        return (
            <>
                <Wrapper>
                    <Title className={classes.title} mb="lg">Live VPN Detection</Title>
                    <Container p={0} size={700}>
                        <Text size="lg"
                              mb="xl"
                              color="dimmed"
                              className={classes.description}>
                            This demo page demonstrates various methods of Proxy and VPN detection.<br/>
                            Read more about VPN detection techniques in our {" "}
                            <a href={BLOG_ARTICLE_LINK}
                               style={{color: "#228be6"}}
                               target="_blank"
                               rel="noreferrer">article</a>
                            , more interesting articles in our {" "}
                            <a href={BLOG_LINK}
                               style={{color: "#228be6"}}
                               target="_blank"
                               rel="noreferrer">blog</a>
                            . Reload the page to try again.
                        </Text>
                    </Container>
                    {content}
                </Wrapper>
                <Header/>
            </>
        )
    }

    if (result) {
        const table = (
            <Container px={0}>
                <Table horizontalSpacing="md"
                       verticalSpacing="md"
                       fontSize="md"
                       striped
                       highlightOnHover
                       withColumnBorders>
                    <thead>
                    <tr>
                        <th>Test Name</th>
                        <th>Test Result</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>{result.map((element) => (
                        <tr key={element.name}>
                            <td>{element.name}</td>
                            <td>{element.result === "Detected" ? (<Text c="red">{element.result}</Text>) : (
                                <Text c="green">{element.result}</Text>)}</td>
                            <td>
                                <Fragment>{element.desc}<br/></Fragment>
                                {
                                    element.details
                                        .split(',')
                                        .map((item, idx) => (<Fragment key={idx}>{item}<br/></Fragment>))
                                }</td>
                        </tr>))}</tbody>
                </Table>
            </Container>
        )
        return getPage(table)
    } else if (error) {
        const alert = (
            <Container>
                <Center>
                    <Alert icon={<IconAlertCircle size="3rem"/>} title="Error!" color="red" radius="md"
                           variant="outline">
                        VPN Detection Demo page is not available, please try again later!
                    </Alert>
                </Center>
            </Container>
        )
        return getPage(alert)
    } else {
        const loading = (
            <Container>
                <Center>
                    <Loader size="lg"/>
                    <Text size="lg"
                          ml="md"
                          color="dimmed">Please wait while we check your connection....</Text>
                </Center>
            </Container>
        )
        return getPage(loading)
    }
}
